<template>
  <div class="main">
    <div style="max-width:1560px;margin:0 auto">
      <el-carousel  height="675px" width="1200px">
        <el-carousel-item v-for="item in banners" :key="item">
          <img :src="item" alt="" class="carouselItem">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container">
      <p style="margin-top:10px">
        <router-link class="position" :to="{name:'index',params:{index:0}}">首页 &gt;&gt;</router-link>
        <router-link class="position" :to="{name:'products',params:{index:3}}"> 产品展示 <span v-if="currentname">&gt;&gt;</span></router-link>
        <router-link class="position" :to="{name:'products',params:{index:3,type:1}}"> {{parentname}} <span v-if="currentname">&gt;&gt;</span></router-link>
        <span class="position"> {{currentname}}</span>
      </p>
      <div class="productscontainer">
        <div class="productscontainerL">
          <div class="productscontainerL1">产品展示</div>
          <router-link tag="div" :to="{path:'/products',query:{type:item.type,index:0}}" class="productscontainerL2" v-for="(item,index) in typelist" :key="index">{{item.name}}</router-link>
        </div>
        <div class="productscontainerR">
            <!-- <vue-photo-zoom-pro :out-zoomer="true" :taobao="taobao"  :showType="showType" :url="imgsrc"></vue-photo-zoom-pro>
            <div class="productscontainerRcon">
              <p class="productscontainerR1">{{currentname}}</p>
              <p class="productscontainerR2">暂无价格</p>

            </div> -->
            <p class="productinfo">产品说明</p>
            <div style="padding:0 10px">
              <img style="max-width:100%" :src="infosrc" alt="">
            </div>
            <!-- 相关产品 -->
            <p class="others">相关产品</p>
            <div class="othersCon">
              <div @click="toinfo(item)" class="productitem" v-for="(item,index) in list" :key=index>
                <img :src="item.img" alt="">
                <p>{{item.name}}</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuePhotoZoomPro from 'vue-photo-zoom-pro'
import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'

export default {
  name: 'productsinfo',
  components: {
    vuePhotoZoomPro
  },
  data(){
    return{
      id:null,
      banners:[
        'http://www.szxcd.com.cn/content/img/banner1.jpg',
        'http://www.szxcd.com.cn/content/img/banner2.jpg',
        'http://www.szxcd.com.cn/content/img/banner3.jpg',
        'http://www.szxcd.com.cn/content/img/banner4.jpg',
      ],
      taobao:true,
      showType:true,
      imgsrc:'',
      infosrc:'',
      currentname:'',
      parentname:'',
      currenttype:0,
      typelist:[
        {
          type:1,
          name:'堆垛机系列'
        },
        {
          type:2,
          name:'货叉系列'
        },
        {
          type:3,
          name:'公司案例'
        }
      ],
      list: [
  {
    img: 'http://www.szxcd.com.cn/content/img/p1.jpg',
    op: 'i1.jpg',
    name: 'XCD-GS',
    parentname: '堆垛机系列',
    type: 1,
    id: 1
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/p2.jpg',
    op: 'i2.jpg',
    name: 'XCD-GS',
    parentname: '堆垛机系列',
    type: 1,
    id: 2
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/p3.jpg',
    op: 'i3.jpg',
    name: 'XCD-SH',
    parentname: '堆垛机系列',
    type: 1,
    id: 3
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/p4.jpg',
    op: 'i4.jpg',
    name: 'XCD-SL',
    parentname: '堆垛机系列',
    type: 1,
    id: 4
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h1.jpg',
    op: 'i5.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 5
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h2.jpg',
    op: 'i6.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 6
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h3.jpg',
    op: 'i7.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 7
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h4.jpg',
    op: 'i8.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 8
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h5.jpg',
    op: 'i9.jpg',
    name: 'LD-D-2Z',
    parentname: '货叉系列',
    type: 2,
    id: 9
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h6.jpg',
    op: 'i10.jpg',
    name: 'LD-D-2Z',
    parentname: '货叉系列',
    type: 2,
    id: 10
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h7.jpg',
    op: 'i11.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 11
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h8.jpg',
    op: 'i12.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 12
  },
]
      // infoList:[
      //   {
      //     img:require('http://www.szxcd.com.cn/content/img/i1.jpg'),
      //     id:1
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i2.jpg'),
      //     id:2
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i3.jpg'),
      //     id:3
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i4.jpg'),
      //     id:4
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i5.jpg'),
      //     id:5
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i6.jpg'),
      //     id:6
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i7.jpg'),
      //     id:7
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i8.jpg'),
      //     id:8
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i9.jpg'),
      //     id:9
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i10.jpg'),
      //     id:10
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i11.jpg'),
      //     id:11
      //   },{
      //     img:require('http://www.szxcd.com.cn/content/img/i12.jpg'),
      //     id:12
      //   }
      // ]
    }
  },
  created () {
    window.scrollTo(0,0)
    this.infosrc = 'http://www.szxcd.com.cn/content/img/' + this.$route.query.img;
  },
  watch: {
      //监听路由地址的改变
      '$route.query':{
        handler(newval){
          this.currentname=newval.name,
          this.parentname=newval.parentname,
          this.id=newval.id
        },
        immediate:true
      },
      // id:{
      //   handler(newval){
      //     let list=this.list;
      //     let infoList=this.infoList;
      //     for(let i=0;i<list.length;i++){
      //       if(list[i].id==newval){
      //         this.imgsrc=list[i].img
      //       }
      //     }
      //     for(let i=0;i<infoList.length;i++){
      //       if(infoList[i].id==newval){
      //         this.infosrc=infoList[i].img
      //       }
      //     }
      //   },
      //   immediate:true
      // }
  },
  methods:{
    toinfo(item){
      this.infosrc = 'http://www.szxcd.com.cn/content/img/'+item.op
      // window.scrollTo(0,0)
      // this.$router.push({path:'/productsinfo',query:{parentname:item.parentname,name:item.name}})
    }
  }
}
</script>

<style scoped>
.productscontainerRcon{
  position: absolute;
  left: 450px;
  width: 300px;
  top: 10px;
  z-index: -1;
}
.productscontainerR1{
  color: #70b9ec;
  font-size: 16px;
  line-height: 32px;
}
.productscontainerR2{
  color: #666;
  font-size: 16px;
  line-height: 35px;
}
.othersCon{
  width: 100%;
  height: 285px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding:0 5px;
}
.others{
  line-height: 40px;
  padding: 0 10px;
  margin: 30px 0;
  background-color: #70b9ec;
  color: #fff;
  font-size: 18px;
}
.productImg{
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.productinfo{
  font-weight: bold;
  font-size: 16px;
  color: #444;
  line-height: 30px;
  border-bottom: 2px solid #ccc;
  padding: 0 10px;
  margin-bottom: 10px;
}
::v-deep .img-region{
  width: 420px;
  height: 420px;
  object-fit: cover;
}
.ks-imagezoom-wrap{
  width: 200px;
  height: 200px;
}
::v-deep .el-pagination .btn-next{
  padding: 0 10px;
}
::v-deep .el-pagination .btn-prev{
  padding: 0 10px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #fad749;
}
.pageclass{
  width: 910px;
  margin-left: 290px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.productitem img{
  width: 210px;
  height: 210px;
  object-fit: cover;
}
.productitem p{
  line-height: 43px;
  font-size: 16px;
  color: #444;
  text-align: center;
}
.productitem:hover p{
  color:#fad749;
  transition: all .5s;
}
.productitem{
  font-size: 0;
  display: inline-block;
  width: 210px;
  height: 253px;
  border: 1px solid #ccc; 
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all .5s;
}
.productscontainerL2{
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  background-color: #70b9ec;
  margin-bottom: 1px;
}
.productscontainerL1{
  line-height: 80px;
  font-size: 30px;
  background-color: #000;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
.productscontainerL{
  width: 270px;
}
.productscontainerR{
  width: 910px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  position: relative;
}
.productscontainer{
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
  flex-wrap: wrap;
}
a{
  text-decoration: none;
  font-size: 14px;
  color: #666;
}
.carouselItem{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.active{
  background-color: #ffd800;
}
.position{
  font-size: 14px;
  color: #666;
}
</style>
